import { useRef, useState, useCallback } from "react"
import {
  Box,
  Slide,
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
} from "@mui/material"

import { NetworkError } from "@/base/api"
import { useAppStores } from "@/stores"

const LoginScreen = ({ logo }: { logo?: React.ReactElement }) => {
  const { appStore } = useAppStores()
  const usernameRef = useRef<HTMLInputElement>()
  const passwordRef = useRef<HTMLInputElement>()
  const [result, setResult] = useState({
    invalid: false,
    error: false,
  })

  const handleLogin = useCallback(async () => {
    try {
      await appStore.login(
        usernameRef.current!.value.trim(),
        passwordRef.current!.value
      )
    } catch (error) {
      console.log(error)
      const err = error as NetworkError
      const status = err.response && err.response.status
      setResult({ invalid: status === 401, error: status !== 401 })
      passwordRef.current!.value = ""
      passwordRef.current!.focus()
    }
  }, [appStore])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleLogin()
      }
    },
    [handleLogin]
  )

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Slide in appear>
        <Paper sx={{ py: 1, width: 0.3, maxWidth: 650, minWidth: 350 }}>
          <Grid container direction="column">
            <Grid item sx={{ mb: 2, textAlign: "center" }}>
              {logo}
            </Grid>

            <Grid
              item
              sx={[
                { mb: 2 },
                result.invalid && { backgroundColor: "goldenrod" },
                result.error && { backgroundColor: "salmon" },
              ]}
            >
              {result.invalid && (
                <>
                  <Typography align="center" variant="h6">
                    Invalid email or password.
                  </Typography>
                  <Typography align="center" variant="subtitle1">
                    Please try again
                  </Typography>
                </>
              )}
              {result.error && (
                <>
                  <Typography align="center" variant="h6">
                    Internal error.
                  </Typography>
                  <Typography align="center" variant="subtitle1">
                    Please try again later
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={12} sx={{ mx: 3, mb: 2 }}>
              <TextField
                id="username"
                label="Email"
                fullWidth
                autoFocus
                inputRef={usernameRef}
              />
            </Grid>
            <Grid item xs={12} sx={{ mx: 3, mb: 2 }}>
              <TextField
                id="password"
                label="Password"
                fullWidth
                type="password"
                inputRef={passwordRef}
                InputProps={{
                  onKeyDown: handleKeyDown,
                }}
              />
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Button variant="contained" size="large" onClick={handleLogin}>
                Login
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Slide>
    </Box>
  )
}

export default LoginScreen
